// @ts-nocheck
import { faChevronLeft, faChevronRight, faInfo, faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CryptoJS from 'crypto-js';
import React, { createElement, createRef } from 'react';
import { Button, Col, Form, Modal, Spinner } from 'react-bootstrap';
import { withTranslation } from "react-i18next";
import { Page, pdfjs } from 'react-pdf';
import Service from '../../../Service';
import { upload_file as IconUploadFile } from '../../Assets/SVGIcons';
import Loading from '../../Loading';
import LoadingProgress from './LoadingProgress';
import { jsPDF } from "jspdf";

import { hookstate, StateFragment } from '@hookstate/core';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import FullscreenOutlinedIcon from '@mui/icons-material/FullscreenOutlined';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { debounce } from '../../../drive-feature/utils/hooks';
import formatDateIntlApi from '../../../Utils/format_date_intl';
import '../../Assets/css/pdf-preview.css';
import FileDragDrop from "../../Components/FileDragDrop/FileDragDrop";
import DocumentWithObserver from '../../Documents/DocumentWithObserver';
import FullPreview from './FullPreview';
import getFileHash from '../../../Utils/compress-pdf';
import { searchBusinessNumber } from '../../../data-access/business-number/business-number.api';
import { noDataState } from '../../Components/VerificationOCR/OcrFields';
import { of } from 'rxjs';
import { replacerFunc } from '../../../Utils/init_observer';
import { blue } from "@mui/material/colors";
import { Checkbox } from "@mui/material";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
const currentRangePage = hookstate('1');
// Create our observer
class Main extends React.Component {

    lastClientY;
    lastClientX;
    constructor(props) {
        super(props);
        // console.log(this.objMain, props)
        this.containerRef = createRef();
            this.state = {
            objMain: props.objMain,
            // active: true,
            draggingPreview: false,
            showAll: props.showAll || false,
            doc_id: props.doc_id || null,
            docInfo: props.docInfo || null,
            isGuest: props.isGuest || null,
            view_status: props.view_status || {},
            hash: props.hash || null,
            scale: props.scale || 1,
            fileLength: 0,
            loadedPDF: [],
            fileListHash: [],
            PDFFile: props.file || {},
            // {
            //     file: props.file || null,
            //     password: props.password || null,
            // },
            PDFFiles: props.files || {},
            showLoadingPDFSelect: false,
            PDFLoadSuccess: false,
            noFile: false,
            multiple: props.multiple || false,
            allowUpload: props.upload || false,
            autoFit: props.fit || false,
            controller: props.controller || false,
            infoPanel: props.info || false,
            listPanel: props.list || false,
            fileChangeCallback: props.fileChangeCB,
            fileRemoveCallback: props.fileRemoveCB,
            fullPreview: props.full || false,
            showFullPreview: false,
            modalPassword: false,
            tempPassword: null,
            isFileLoading: props.isFileLoading,
            incorrectPassword: "",
            multipleFilesQueue: [],
            updateInfo: props.updateInfo,
            filesSort: props.filesSort,
            pagesRendered: 0,
            ocrFound: {},
            isShowViewConfirm: false,
            isViewed: false,
            token: props.token,
            isLastPageRendered: {},
            progressContainer: 0,
            isProcessingContainer: false,
            preShowFiles: {},
            width: 0, 
            height: 0 
        }
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
		this.state.showLoadingPDFLoad = false;

        if (!props.files && props.file) {
            let file = this.state.PDFFile;
            this.state.PDFFiles[file.hash] = file;
        }

        if (props.filesSort) {
            this.state.filesSort = props.filesSort;
        }

        this.pdfDocOptions = {
            cMapUrl: `https://cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
            cMapPacked: true,
        };
        // this.state.observer = initObserver(currentRangePage);
        this.handleChangeFileOrder = props.handleChangeFileOrder;
        this.onConfirmView = props.onConfirmView;
    }

    handleView = (status) => {
        this.setState({ view_status: status }, () => {
            this.onConfirmView(status);
        });
    }

    
    componentWillUnmount() {
        window.removeEventListener('mouseup', this.mouseUpHandle.bind(this));
        window.removeEventListener('mousemove', this.mouseMoveHandle.bind(this));
        window.removeEventListener('resize', this.updateWindowDimensions);
        clearInterval(this.progressInterval);
    }
    mouseUpHandle(e) {
        if (this.state.dragging) {
        //   this.state.dragging = false;
          this.setState({dragging: false});
        }
    }
    
    mouseDownHandle(e) {
        // console.log(e)
        if (!this.state.dragging) {
        //   this.state.dragging = true;
          this.setState({dragging: true});
        //   this.setState(this.state);
          this.lastClientX = e.clientX;
          this.lastClientY = e.clientY;
          e.preventDefault();
        }
    }
    
    mouseMoveHandle(e) {
        if (this.state.dragging) {
            // console.log(this.containerRef)
          this.containerRef.current.scrollLeft -=
            (-this.lastClientX + (this.lastClientX = e.clientX));
          this.containerRef.current.scrollTop -=
            (-this.lastClientY + (this.lastClientY = e.clientY));
        }
    }
    generateHTMLOcr = (imprint) => {
        let { scale, PDFFile } = this.state;
        // console.log(imprint,PDFFile.pages[imprint.page].view.height,PDFFile.pages[imprint.page].view.height - imprint.top * scale * 2 - imprint.defaultHeight, PDFFile.pages[imprint.page])
        imprint.style = {
            width: (imprint.defaultWidth * scale + 2) + "px",
            height: (imprint.defaultHeight * scale + 2) + "px",
            left: (((imprint.left - 3) * scale)) + "px",
            // top:( (PDFFile.pages[imprint.page].view.height - imprint.top - imprint.defaultHeight) * scale) + "px",
            top: ((imprint.top - 3) * scale) + "px",
            fontSize: (scale * 11) + "px", // Scale / 100 * fontSize
            cursor: 'pointer !important'
        }
        // console.log('imprint', imprint)
		return (
			<div key={imprint.id}
				id={imprint.id}
				data-id={imprint.id}
				data-type={imprint.type}
				data-group={imprint.group}
				data-width={imprint.defaultWidth}
				data-height={imprint.defaultHeight}
				// onDragStart={this.handleDragStart}
				// onMouseDown={this.imprintHandleMouseDown}
				className={imprint.className}
				style={imprint.style}
                onClick={(e)=>{
					let {formData}= this.state.objMain.state;
					formData[imprint.type] = imprint.value;
                    this.state.objMain.setState({
                        formData: formData,
                    });
                    // }
                }}
			>
				{/* <imprint.Icon className={imprint.group + "-" + imprint.type} />
				<span className="label-text">{labelText}</span> */}
			</div>
		)
	}
    
    static getDerivedStateFromProps(props, state) {
        // console.log(state);
        if (state.hash !== props.hash) {
            state.hash = props.hash;
            state.doc_id = props.doc_id || null;
            state.docInfo = props.docInfo || null;
            state.scale = props.scale || 1;
            state.PDFFile = props.file || {};
            // state.PDFFile.password = props.password || null;
            // console.log("getDerivedStateFromProps", state.PDFFile.initialDone);
            state.PDFFile.initialDone = false;
            state.PDFLoadSuccess = false;
        }

        if (state.filesSort !== props.filesSort) {
            state.filesSort = props.filesSort;
        }

        return { state };
    }

    componentDidMount() {
        let { doc_id, hash, PDFFile, PDFFiles, filesSort } = this.state;
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        window.addEventListener('mouseup', this.mouseUpHandle.bind(this));
        window.addEventListener('mousemove', this.mouseMoveHandle.bind(this));
        if (!PDFFile.file) {
            if (Object.values(PDFFiles).length > 0) {
                if (filesSort !== null && filesSort?.length > 0) {
                    PDFFile = PDFFiles[filesSort[0]];
                } else {
                    PDFFile = Object.values(PDFFiles)[0];
                }
                if (PDFFile.url) {
                    PDFFile.file = PDFFile.url;
                }
                PDFFile.initialDone = false;
                this.setState({ PDFFile });
            } else if (doc_id && hash) {
                Service.getDocumentFile(doc_id, hash).then(resp => {
                    // console.log(resp);
                    // SelectedFile = "9a192d8b1a7dc652a19835f6f08098bd";
                    PDFFile.file = resp;
                    PDFFile.initialDone = false;
                    this.setState({ PDFFile });
                }).catch(err => {
                    //console.log(err.resonse);
                });
            } else {
                this.setState({ noFile: true });
            }
        } else if (PDFFile.url) {
            PDFFile.file = PDFFile.url;
            PDFFile.initialDone = false;
            this.setState({ PDFFile });
        }
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    // componentDidUpdate() {
    //     let { PDFFile, PDFFiles, multipleFilesQueue } = this.state;
    //     console.log(multipleFilesQueue);
    //     multipleFilesQueue.shift();
    //     this.setState({ multipleFilesQueue });
    // }

    DateFromMetadata = (value) => {
        if (!value) {
            return null;
        }
        try {
              let pdfDateStringRegex = new RegExp("^D:" + "(\\d{4})" + "(\\d{2})?" + "(\\d{2})?" + "(\\d{2})?" + "(\\d{2})?" + "(\\d{2})?" + "([Z|+|-])?" + "(\\d{2})?" + "'?" + "(\\d{2})?" + "'?");

              const matches = pdfDateStringRegex.exec(value);
          
              if (!matches) {
                return null;
              }
          
              const year = parseInt(matches[1], 10);
              let month = parseInt(matches[2], 10);
              month = month >= 1 && month <= 12 ? month - 1 : 0;
              let day = parseInt(matches[3], 10);
              day = day >= 1 && day <= 31 ? day : 1;
              let hour = parseInt(matches[4], 10);
              hour = hour >= 0 && hour <= 23 ? hour : 0;
              let minute = parseInt(matches[5], 10);
              minute = minute >= 0 && minute <= 59 ? minute : 0;
              let second = parseInt(matches[6], 10);
              second = second >= 0 && second <= 59 ? second : 0;
              const universalTimeRelation = matches[7] || "Z";
              let offsetHour = parseInt(matches[8], 10);
              offsetHour = offsetHour >= 0 && offsetHour <= 23 ? offsetHour : 0;
              let offsetMinute = parseInt(matches[9], 10) || 0;
              offsetMinute = offsetMinute >= 0 && offsetMinute <= 59 ? offsetMinute : 0;
          
              if (universalTimeRelation === "-") {
                hour += offsetHour;
                minute += offsetMinute;
              } else if (universalTimeRelation === "+") {
                hour -= offsetHour;
                minute -= offsetMinute;
              }
          
              return new Date(Date.UTC(year, month, day, hour, minute, second)).toISOString();
        } catch (ex) {
            // console.log('ẽ', ex)
            return value;
        }
    }

    onDocumentLoadSuccess = async (pdfObject) => {
        let { numPages } = pdfObject;
        let {
            PDFFiles,
            PDFFile,
            showAll,
            autoFit,
            scale,
            fullPreview,
            // multipleFilesQueue
        } = this.state;
        console.log('pdfObject', PDFFile?.initialDone);
        // console.log(pdfObject);
        // console.log(PDFFiles);
        // console.log(PDFFile);
        // console.log(PDFFile.initialDone);
        // console.log(tempPDFPassword, keepPDFPassword);
        if (!PDFFile?.initialDone) {

            this.setState(prevState => ({
                showLoadingPDFLoad: true
            }));
            // let pdfOutline = await pdfObject.getOutline();
            // console.log(pdfOutline);
            let pdfMeta = await pdfObject.getMetadata();
            PDFFile.info = pdfMeta.info;
			console.log('hello', await pdfObject.getStats())
            if (pdfMeta.metadata) {
                PDFFile.metadata = pdfMeta.metadata.getAll();
            } else {
                PDFFile.metadata = {};
            }
            // console.log(PDFFile.info);
            // console.log(pdfMeta);
            PDFFile.info.CreateDate = this.DateFromMetadata(PDFFile.info.CreationDate);
            PDFFile.info.ModifyDate = this.DateFromMetadata(PDFFile.info.ModDate || PDFFile.info.CreationDate);

            PDFFile.numPages = numPages;
            PDFFile.currentPage = PDFFile.currentPage || 1;
            let pageSpace = 20;
            let docHeight = 0;
            let pages = {};
            for (let i = 1; i <= numPages; i++) {
                let pageInfo = await pdfObject.getPage(i);
                let opList = await pageInfo.getOperatorList();
                let scan = null;
                let pos = null;
                let pageView = {
                    top: pageInfo.view[0],
                    left: pageInfo.view[1],
                    width: pageInfo.view[2],
                    height: pageInfo.view[3],
                };
                // console.log(await pageInfo.getTextContent());

                if ([90, 270].includes(pageInfo.rotate)) {
                    pageView.width = pageInfo.view[3];
                    pageView.height = pageInfo.view[2];
                }

                // console.log(pageInfo);
                for (var ii = 0; ii < opList.fnArray.length; ii++) {
                    // paintInlineImageXObject paintImageXObject
                    if (opList.fnArray[ii] === pdfjs.OPS.transform) {
                        pos = opList.argsArray[ii];
                    }
                    if (opList.fnArray[ii] === pdfjs.OPS.paintImageXObject) {
                        // console.log(pdfjs.OPS);
                        // console.log(opList);
                        let img = opList.argsArray[ii];
                        // let imgObject = pageInfo.objs.get(img[0]);
                        // console.log(img);
                        // console.log(imgObject);
                        // for (let mm = ii - 1; mm < 6; mm--) {
                        //     let pos = opList.argsArray[ii - 3];
                        // }
                        if (pos) {
                            if (pos[4] === 0 && pos[5] === 0) {
                                scan = {
                                    key: img[0],
                                    width: img[1],
                                    height: img[2],
                                    index: ii,
                                    position: pos,
                                    // dpi: Math.round(img[1] / (pageView.width / 72)),
                                    dpi: Math.round(img[1] / 8.27),
                                    // key: imgKey,
                                };
                                break;
                            }
                        }
                    }
                }

                if (scan === null) {
                    scan = {
                        key: 'dummyImage',
                        width: 2893,
                        height: 4092,
                        index: 0,
                        position: [2893, 0, 0, 4092, 0, 0],
                        // dpi: Math.round(2893 / (842.04 / 72)),
                        dpi: Math.round(2893 / 8.27),
                        // key: imgKey,
                    };
                }

                // let textFound = null;
                // if (this.state.multiple) {
                //     textFound = (await pageInfo.getTextContent()).items;
                //     PDFFile.textFound = textFound;
                // }
                pages[i] = {
                    view: pageView,
                    scan: scan,
                    // pageInfo: pageInfo
                };

                docHeight += pageView.height + pageSpace;
            }
            docHeight += pageSpace;
            let currentHeight = pageSpace;
            for (let i in pages) {
                let view = pages[i].view;
                pages[i].view.top = currentHeight;
                pages[i].view.percentage = {
                    top: currentHeight / docHeight * 100,
                    height: view.height / docHeight * 100,
                };
                currentHeight += view.height + pageSpace
            }
            pages[1].view.top = 0;
            pages[1].view.percentage.top = 0;

            PDFFile.pages = pages;
            PDFFile.initialDone = true;
            if (autoFit) {
                // Exclude the width of the PDF information section on the right side (340px) for 742px or more.
                let parentWidth =
                    window.innerWidth > 742
                        ? window.innerWidth - 340
                        : window.innerWidth;
                let parentHeight = window.innerHeight - 120;

                if (!fullPreview) {
                    // Non-fullscreen previews are set to fixed values for height and width.
                    let parentBlock = document.querySelector("#pdf_main_preview.pdf-fragment .pdf-preview-section");
                    parentWidth = parentBlock.offsetWidth - 40;
                    parentHeight = parentBlock.offsetHeight - 60;
                }

                if (showAll) {
                    parentWidth -= 40;
                }

                // console.log(parentWidth, parentHeight);
                // console.log(pages[1]);

                let wScale = parentWidth / pages[1].view.width;
                let hScale = parentHeight / pages[1].view.height;
                // console.log(wScale, hScale);
                // console.log(pages[1].view.width, pages[1].view.height);
                // console.log('parentWidth');

                scale = wScale;
                if (hScale < wScale) {
                    scale = hScale;
                }
                if (scale < 0.1) {
                    scale = 0.1;
                }

                // if (showAll) {
                //     // console.log(scale);
                //     scale = scale * 10;
                //     if(wScale > 0.6) {
                //         scale = scale - 3;
                //     } else {
                //         scale = scale - 0.95;

                //     }
                //     scale = (Math.round(scale * 100) / 100).toFixed(2);
                //     scale = (+scale) / 10;
                //     // console.log(scale);
                // }
                // scale = parseFloat(scale.toFixed(1));
            }
            if (PDFFile.tryPassword) {
                PDFFile.password = PDFFile.tryPassword;
                PDFFiles[PDFFile?.hash].password = PDFFile.password;
                // PDFFile.password = PDFFile.password;
                this.state.objMain.state.PDFFiles[PDFFile?.hash].password = PDFFile.password;
                // console.log('this.state.objMain.state.PDFFiles', PDFFile?.hash)
                this.state.objMain.setState({PDFFiles: this.state.objMain.state.PDFFiles})
                this.setState({ PDFFiles });
                delete PDFFile.tryPassword;
            }
            this.setState({ PDFFile, scale });
        }
        // console.log(PDFFile.numPages)
        this.setState({ PDFLoadSuccess: true, pagesRendered: 0});
    }

    onDocumentLoadError = (pdfObject) => {
        // console.log(pdfObject);
        let { t } = this.props;

        return (
            <div className="pdf-no-data">
                <span>{t("common:message.pdf-preview.load-pdf-fail")}</span>
            </div>
        );
    }

    setStateAfterSuccess(self, p) {
        let { PDFFiles, PDFFile, multipleFilesQueue,fileListHash, fileLength,loadedPDF } = this.state;
        // console.log('pdfObject',multipleFilesQueue);
        let multi = multipleFilesQueue.reduce((arr,item)=> {
            if(!arr.includes(item)) arr.push(item)
            return arr
        }, [])
        if(fileListHash.length < fileLength){
            this.setState({showLoadingPDFSelect: true});
            this.state.objMain.setState({isFileLoading: true});
        }

        if(fileListHash.length === fileLength){
            this.setState({showLoadingPDFSelect: false});
            
            // this.state.objMain.setState({isFileLoading: false});
        }

        if(this?.state?.objMain?.state?.isFileLoading){
            if(multi.length === 1){
                this?.state?.objMain?.setState({isFileLoading: false});
            } else {
                this?.state?.objMain?.setState({isFileLoading: true});
            }
            if (multi.length > 0 && fileListHash.length > 1) {
                let nextFileHash = multi.shift();
                PDFFile = PDFFiles[nextFileHash];
                // console.log(loadedPDF,nextFileHash, multi)
                if(!loadedPDF.includes(nextFileHash)){
                    loadedPDF.push(nextFileHash);
                    this.setState({ PDFFile, multipleFilesQueue: multi, loadedPDF, isFileLoading: this.state.showLoadingPDFSelect });
                    this.state.objMain.setState({isFileLoading:  this.state.showLoadingPDFSelect});
                } else { 
                    // let nextFileHash = multi.shift();
                    // console.log('2',nextFileHash, PDFFile)
                    if(multi > 0 && !loadedPDF.includes(nextFileHash)){
                        PDFFile = PDFFiles[nextFileHash];
                        loadedPDF.push(nextFileHash);
                        this.setState({ PDFFile, multipleFilesQueue: multi,loadedPDF, isFileLoading: this.state.showLoadingPDFSelect });
                        this.state.objMain.setState({isFileLoading:  this.state.showLoadingPDFSelect});
                    } else {          
                        if(nextFileHash !== PDFFile.hash) {
                            PDFFile = PDFFiles[nextFileHash];
                            if(!PDFFile){
                                PDFFile = PDFFiles[Object.keys(PDFFiles).length - 1];
                            }
                            if(PDFFile){
                                loadedPDF.push(nextFileHash);
                                this.setState({PDFFile, loadedPDF});
                            }
                            this.state.objMain.setState({isFileLoading:  this.state.showLoadingPDFSelect});
                            
                        }        
                        this.setState({ multipleFilesQueue: multi, isFileLoading: this.state.showLoadingPDFSelect });
                        //  this.state.objMain.setState({isFileLoading:  this.state.showLoadingPDFSelect});
                    }
                }
                if(loadedPDF.length === fileLength){
                    this.state.objMain.setState({isFileLoading: false});
                }
            } 
        }
    }
    
    onDocumentRenderSuccess = debounce((pdfObject) => this.setStateAfterSuccess(this, pdfObject), 700);

    onPasswordHandler = (fnCallback) => {

        // let { t } = this.props;
        let { PDFFiles, PDFFile, modalPassword, modalPasswordClose, tempPassword } = this.state;

        // console.log(PDFFile);
        if (PDFFile.password) {
            fnCallback(PDFFile.password);
        } else {
            // var PDFFile.tryPassword = prompt("Please enter your name", "Harry Potter");
            modalPassword = true;
            tempPassword = "";
            modalPasswordClose = () => {
                let { PDFFile, PDFFiles } = this.state;
                fnCallback(PDFFile.tryPassword);
                // PDFFile?.file
            }
            this.setState({ modalPassword, tempPassword, modalPasswordClose });
        }
    }

    PDFPages = () => {
        let { PDFFile,PDFFiles, showAll, scale, pagesRendered } = this.state;
        // console.log('run')
        if (!PDFFile.initialDone) {
            return null;
        }
        // let Pages = [];
        const pagesRenderedPlusOne = Math.min(pagesRendered + 1, PDFFile.numPages || 0);
        if (this.props.isThumbnail) {
          return (
            <Page
              renderMode="canvas"
              pageNumber={PDFFile.currentPage}
              renderTextLayer={false}
              onRenderSuccess={()=>{
                this.setState(prevState => ({
                    showLoadingPDFLoad: false,
                }));
              }}
              scale={scale}
              className={`pdf-custom-page scale-${scale}${" active"}`}
            >
              {/* {OcrFound} */}
            </Page>
          );
        }
		// console.log(pagesRenderedPlusOne)
		let Pages = Array.from(
			new Array(pagesRenderedPlusOne < 0 ? 0 : pagesRenderedPlusOne),
			(el, i) => {
			  const isCurrentlyRendering = pagesRenderedPlusOne === i + 1;
			  const isLastPage = PDFFile.numPages === i + 1;
			  const needsCallbackToRenderNextPage = isCurrentlyRendering && !isLastPage;
			  let active = "";
                // console.log(i + 1, PDFFile.currentPage);
                if (i + 1 === parseInt(PDFFile.currentPage)) {
                    active = " active";
                }
                if (showAll) {
                    active = " active";
                }
                let OcrFound = []
                // console.log(this.state?.objMain?.state?.ocrFound[PDFFile.hash])
                if(this.state?.objMain?.state?.ocrFound[PDFFile.hash] 
                    && this.state?.objMain.state?.ocrFound[PDFFile.hash][i] && 
                    this.state?.objMain?.state?.activePage?.name !== 'Determine') {
                    OcrFound = this.state?.objMain?.state?.ocrFound[PDFFile.hash].reduce((arr,item)=>{
                        if(item.page - 1 === i){
                            // console.log(item.page - 1, i)
                            arr.push(this.generateHTMLOcr(item));
                        }
                        return arr;
                    }, []);
                    // console.log(ocrInPage)
                    // OcrFound = ocrInPage.m
                    // OcrFound.map(ocrInPage);
                    // OcrFound.push(this.generateHTMLOcr(this.state?.objMain?.state?.ocrFound[PDFFile.hash][i]));
                }

                // for (let index = 0; index < PDFFile.numPages; index++) {
                //     // const element = array[index];
                //     console.log(this.state?.ocrFound[PDFFile.hash])
                //     // let OcrFound = this.state?.ocrFound[PDFFile.hash]?.filter(a=> a.page == PDFFile.currentPage).map(a=>  this.generateHTMLOcr(a)) ;
                    
                // }
                // if(this.state.ocrFound[PDFFile.hash]) OcrFound = createElement(this.state.ocrFound[PDFFile.hash]);
                // console.log(OcrFound)
                return (
                    <div key={i} className={`pdf-page-panel${active}`}>
                        {/* <Page pageNumber={i + 1} renderTextLayer={false} scale={previewScale / 100} onDragOver={this.allowDrop} className={"pdf-custom-page scale-" + previewScale}> */}
                        <Page onRenderSuccess={(pdfObject)=> {
                            if (isLastPage) {
                                this.setState({ isLastPageRendered: { [PDFFile.hash]: true }});
                            }
                            // console.log(this.state.objMain?.state?.activePage?.name)
                                if(this.state.objMain?.state?.activePage?.name === 'Determine' || this.state.objMain?.state?.activePage?.name === 'TemplateInfo'){
                                    // if(i === 0) this.state.objMain.setState((prevState)=> ({isFileLoading: false}));
                                    if(needsCallbackToRenderNextPage){
                                        if(i === 0){
                                            this.setState(prevState => ({
                                                pagesRendered: prevState.pagesRendered + 1,
                                                showLoadingPDFLoad: false
                                            }));
                                        } else {
                                            this.setState(prevState => ({
                                                pagesRendered: prevState.pagesRendered + 1
                                            }));
                                        }
                                    } else{
                                        if(i === 0){
                                            this.setState(prevState => ({
                                                showLoadingPDFLoad: false,
                                            }));
                                        } 
                                    }
                                    this.onDocumentRenderSuccess(pdfObject)
                                    return;
                                }
                                if(needsCallbackToRenderNextPage){
                                    if(i === 0){
                                        this.setState(prevState => ({
                                            pagesRendered: prevState.pagesRendered + 1,
                                            showLoadingPDFLoad: false
                                        }));
                                        this.state.objMain.setState((prevState)=> ({isFileLoading: Object.keys(this?.state?.PDFFiles || {}).length > 1 ? prevState.isFileLoading: false}));
                                    } else {
                                        this.setState(prevState => ({
                                            pagesRendered: prevState.pagesRendered + 1
                                        }));
                                    }
                                } else{
                                    if(i === 0){
                                        this.setState(prevState => ({
                                            showLoadingPDFLoad: false,
                                            isFileLoading: Object.keys(this?.state?.PDFFiles || {}).length > 1 ? prevState.isFileLoading: false
                                        }));
                                        this.state.objMain.setState((prevState)=> ({isFileLoading: Object.keys(this?.state?.PDFFiles || {}).length > 1 ? prevState.isFileLoading: false}))
                                    } else {
                                        this.state.objMain.setState((prevState)=> ({isFileLoading: Object.keys(this?.state?.PDFFiles || {}).length > 1 ? prevState.isFileLoading: false}))
                                    }
                                }
                                this.onDocumentRenderSuccess(pdfObject)
                            }} key={i} renderMode="canvas" pageNumber={i + 1} renderTextLayer={false} scale={scale} className={`pdf-custom-page scale-${scale}${active}`} >
                                   {OcrFound}
                            </Page>
                         
                    </div>
                );
			},
		  );

        // console.log("PageRender", Pages);
        return Pages;
    }

    nextPagePreview = (ev) => {
        let { PDFFile } = this.state;
        if (PDFFile.currentPage < PDFFile.numPages) {
            PDFFile.currentPage++;
            this.setState({ PDFFile });
        }
    }

    prevPagePreview = (ev) => {
        let { PDFFile } = this.state;
        if (PDFFile.currentPage > 1) {
            PDFFile.currentPage--;
            this.setState({ PDFFile });
        }
    }

    increasePreviewSize = (ev) => {
        let { scale, PDFFile } = this.state;
        scale += 0.3;
        if (scale > 10) {
            scale = 10;
        }
        scale = parseFloat(scale.toFixed(2));
        this.setState({ scale, autoFit: false });
    }

    decreasePreviewSize = (ev) => {
        let { scale, PDFFile } = this.state;
        scale -= 0.3;
        if (scale < 0.1) {
            scale = 0.1;
        }
        scale = parseFloat(scale.toFixed(2));
        this.setState({ scale, autoFit: false });
    }

    PDFPageNoData = () => {
        let { t } = this.props;

        return (
            <div className="pdf-no-data">
                <span>{t("common:message.pdf-preview.no-data")}</span>
            </div>
        );
    }

    PageNavigate = () => {
        let { showAll, PDFFile, noFile } = this.state;

        if (showAll || noFile || !PDFFile.initialDone) {
            return null;
        }

        let PageInfoLable = `${PDFFile.currentPage || 0}/${PDFFile.numPages || 0}`;
        if (!PDFFile.currentPage || !PDFFile.numPages) {
            PageInfoLable = "Loading...";
        }

        return (
            <div className="page-navigator">
                <button type="button" className="btn-icon" onClick={this.prevPagePreview}>
                    <FontAwesomeIcon icon={faChevronLeft} />
                </button>
                <div className="info-label">{PageInfoLable}</div>
                <button type="button" className="btn-icon" onClick={this.nextPagePreview}>
                    <FontAwesomeIcon icon={faChevronRight} />
                </button>
            </div>
        );
    }

    MiniViewAction = () => {
        let { t } = this.props;
        let { showAll, PDFFiles, PDFFile, noFile, listPanel, docInfo, isGuest } = this.state;
        let ButtonList = [];

        if (showAll || noFile || !PDFFile.initialDone || !PDFFiles) {
            return null;
        }

        if (listPanel) {
            ButtonList.push(
                <Tooltip key={0} title={t("common:tooltip.common.view-pdf-information")} placement="top-end">
                    <button key="show-pdf-info" className="btn-icon show-pdf-info" onClick={(ev) => {
                        this.setState({ infoPanel: true });
                    }}>
                        <InfoIcon />
                    </button>
                </Tooltip>
            );
        }

        let isShowRipple = !!docInfo?.document_view_required && (isGuest || docInfo?.isGuestCollaboration);
        if(docInfo?.isGuestCollaboration && docInfo?.signed === 1){
            isShowRipple = false
        }

        ButtonList.push(
            <Tooltip key={1} title={t("common:tooltip.common.view-pdf")} placement="top-end">
                <button key="full-preview" className="btn-icon full-preview" onClick={(ev) => {
                    this.setState({ showFullPreview: true });
                }}>
                    {/* <span className={`btn-fullscreen ${isShowRipple ? "ripple" : ""}`}><FullscreenOutlinedIcon /></span> */}
                </button>
            </Tooltip>
        );

        // if (PDFFile.url) {
        //     ButtonList.push(
        //         <Tooltip key={2} title={t("common:tooltip.common.new-window")} placement="bottom-end">
        //             <a key="open-in-new-window" className="btn-icon new-window" target='_blank' rel="noreferrer" href={PDFFile.url}>
        //                 <OpenInNewIcon />
        //             </a>
        //         </Tooltip>
        //     );
        // }

        return (
            <div key={0} className="mini-view-action-panel">
                {ButtonList}
            </div>
        );
    }

    delay = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds));
    }
    bufferToString = (buffer) => {
        const bytes = new Uint8Array(buffer)
        return bytes.reduce((string, byte) => (string + String.fromCharCode(byte)), "")
    }

    initOcrPreview(PDFFiles, fileKey, list, b, originalList, i, type, value){
        // console.log(currentRangePage.get(), pageView);
        // console.log(pageView);
        let space = 4;
        // console.log(b);
        let imprint = {
            id: `${type}_${Math.random()}_${b.page}_${fileKey}` ,
            page: b.page,
            top: b.y,
            left: b.x,
            defaultWidth: b.width + space,
            defaultHeight: b.height  + space,
            className: `ocr-block ocr_block_${type}`,
            style: {},
            value: value,
            type: type
        };
        // console.log(pageWidth, pageView.height)
        // imprint.className = ``;
        list.push(imprint);

        let {ocrFound, suggestionData} = this.state.objMain.state;
        if(!suggestionData[fileKey]){
            suggestionData[fileKey] = {};
        }
        if(!suggestionData[fileKey][type]){
            suggestionData[fileKey][type] = [imprint.value];
        } else {
            // if(!suggestionData[fileKey][type]) suggestionData[fileKey][type] = [];
            if (suggestionData[fileKey][type]?.push) suggestionData[fileKey][type].push(imprint.value);
        }
        if(type !== 'invoice_number') {
            if(!ocrFound[fileKey]?.length){
                ocrFound[fileKey] = [imprint];
            } else {
                ocrFound[fileKey].push(imprint);
            }
            if(i === originalList.length - 1){
                // console.log('ocrFound',type, ocrFound[fileKey],imprint );
                this.state?.objMain?.setState({ocrFound, suggestionData});
                // console.log('list', list)
            }
        };
    }

    getPageCount = async (file) => {
        const defaultPageCount = 1
        if (!file) {
            return defaultPageCount
        }
        try {
            const arrayBuffer = await file.arrayBuffer();
            if (arrayBuffer) {
                const dataUrl = URL.createObjectURL(new Blob([arrayBuffer], { type: 'application/pdf' }));
                const loadingTask = pdfjs.getDocument(dataUrl);

                const pdfDocument = await loadingTask.promise;
                return pdfDocument.numPages;
            }
        } catch (error) {
            console.log('Error loading PDF:', error);
            return defaultPageCount
        }
    }

    RegisterFiles = (files, fnCallback) => {
        if (files.length === 0) {
            this.setState({showLoadingPDFSelect: false});
            this.state.objMain.setState({isFileLoading: false});
            return;
        }

        let { multiple, fileLength, PDFFiles, fileListHash, loadedPDF } = this.state;
        this.state.objMain.setState({
             formData: {...this.state.objMain?.state.formData, invoice_number: ''},
        }); 
        
        fileLength = files.length + Object.keys(PDFFiles).length;
        loadedPDF = Object.keys(PDFFiles);
        // console.log(PDFFiles);
        fileListHash = Object.keys(PDFFiles);
        this.setState({fileLength})
        for (let file of files) {
            // const objUrl = URL.createObjectURL(file);
            // const a = await fetch(objUrl);
            // const b = await a.arrayBuffer();
            // let content = CryptoJS.enc.Latin1.parse(this.bufferToString(b));
            getFileHash(file).then(async (fileKey) => {
                this.setState({showLoadingPDFSelect: false});
                // console.log(fileKey);
                let { suggestionData,isSuggestionLoading,formData } = this.state.objMain?.state;
                let { PDFFiles, multipleFilesQueue,  } = this.state;
                if (!multiple) {
                    PDFFiles = {};
                }
               if (!PDFFiles.hasOwnProperty(fileKey)) {
                    PDFFiles[fileKey] = {
                        hash: fileKey,
                        file: file,
                        name: file.name,
                        type: file.type,
                        size: file.size,
                        temp: file.temp,
                        lastModified: file.lastModified,
                        lastModifiedDate: file.lastModifiedDate,
                        regenerate_cover: file.regenerate_cover,
                    };
                }
                multipleFilesQueue.push(fileKey);
                fileListHash.push(fileKey);
                const PDFFile = PDFFiles[fileKey];
                if(fileListHash.length < fileLength){
                    this.setState({showLoadingPDFSelect: true});
                    this.state.objMain.setState({isFileLoading: true});
                }
        
                if(fileListHash.length === fileLength){
                    this.setState({showLoadingPDFSelect: false});
                }
                if(fileListHash.length === fileLength){
                    loadedPDF.push(fileKey);
                    // this.state.objMain.setState({isFileLoading: false});
                }
                // const numPages = 1;
                let numPages = PDFFile?.numPages
                if (!numPages) {
                    numPages = await this.getPageCount(file);
                }
                if (file.attachments) {
                    const attachments = [];
                    for (const attachment of file.attachments) {
                        const fileKey = await getFileHash(attachment)
                        attachments.push({
                            hash: fileKey,
                            file: attachment,
                            name: attachment.name,
                            type: attachment.type,
                            size: attachment.size,
                            temp: attachment.temp,
                            lastModified: attachment.lastModified,
                            lastModifiedDate: attachment.lastModifiedDate,
                        })
                    }
                    PDFFiles[fileKey].attachments = attachments;
                }
                this.setState({ PDFFiles, PDFFile, noFile: false, fileListHash, loadedPDF}, ()=>{
                    if(file.type.includes('pdf')){
                        let { hide_suggestion_field, formData} = this.state.objMain.state;
                        const formDataFile = new FormData();
                        formDataFile.append('file', file, file.name);
                        isSuggestionLoading[fileKey] = true;
                        hide_suggestion_field[PDFFile?.hash] = {
                            received_date: false,
                            invoice_number: false,
                            amount: false,
                            counter_party_name: false
                        }
                        
                        this.state.objMain.setState({isSuggestionLoading, hide_suggestion_field});
                        noDataState.counter_party_name.set(v=> ({...v, [fileKey]: false}));
                        noDataState.received_date.set(v=> ({...v, [fileKey]: false}));
                        noDataState.amount.set(v=> ({...v, [fileKey]: false}));
                        Service.extractFileText(formDataFile, numPages, PDFFile?.hash, formData?.function_type).then(r=> {
                            // console.log(r);
                            let payload = r?.payload;
                            let findExist = payload?.invoice_number_list?.find(a=> a.exist);
                            let PDFFile2 = PDFFiles[fileKey];
                            let { previewScale } = this.state.objMain.state;
                            // console.log('hello', previewScale, PDFFiles[fileKey]);
                            let invoiceNumberList = r?.payload?.invoice_number_list || [];
                            
                            

                            if(payload?.document_type) {
                                let {document_type_by_key, PageData } = this.state.objMain?.state;
                                if(PageData?.doc_types?.some(a=> a.id == payload.document_type)){
                                    document_type_by_key[fileKey] = payload.document_type;
                                    this.state.objMain.setState({document_type_by_key}, ()=>{
                                        let {formData} = this.state.objMain.state;
                                        if(PDFFile?.hash === fileKey){
                                            formData.document_type = document_type_by_key[fileKey];
                                            this.state.objMain.setState({formData});
                                        }
                                    });
                                }
                            }
                        
                            if(!payload?.date_list?.length) {
                                noDataState.received_date.set(v=> ({...v, [fileKey]: true}));
                            } else {
                                let { suggestionData } = this.state.objMain?.state;
                                if(!suggestionData[fileKey]){
                                    suggestionData[fileKey] = {};
                                }
                                noDataState.received_date.set(v=> ({...v, [fileKey]: false}));
                                suggestionData[fileKey]['received_date'] = payload.date_list.map((a)=>{
                                    // a.date = a.date.replaceAll('/', '-');
                                    // a.date = a.date.replaceAll('.', '-');
                                    let date = new Date(a.date);
                                    date.setHours(12);
                                    date = date.toISOString().substring(0, 10);
                                    // console.log(a);
                                    if(a.locations.length === 0){
                                        noDataState.received_date.set(v=> ({...v, [fileKey]: true}));
                                    }
                                    a.locations.forEach((b,i)=>{
                                        this.initOcrPreview(PDFFiles, fileKey, [], b, a.locations, i, 'received_date', date);
                                    });
                                    // console.log(this.state.objMain.state.ocrFound)
                                    // this.initOcrPreview(PDFFiles, fileKey, [], b, payload.date_list, i, 'expired_date',date );
                                    return date
                                });
                            }

       
                            if(!payload?.amount_list?.length) {
                                noDataState.amount.set(v=> ({...v, [fileKey]: true}));
                            } else {
                                let { suggestionData } = this.state.objMain?.state;
                                if(!suggestionData[fileKey]){
                                    suggestionData[fileKey] = {};
                                }
                                noDataState.amount.set(v=> ({...v, [fileKey]: false}));
                                suggestionData[fileKey]['amount'] = payload?.amount_list.map(a => {
                                    a.locations.forEach((b,i)=>{
                                        this.initOcrPreview(PDFFiles, fileKey, [], b, a.locations, i, 'amount', a.amount);
                                    });
                                    return a.amount
                                });

                            }
                            if(!payload?.counter_party?.length) {
                                noDataState.counter_party_name.set(v=> ({...v, [fileKey]: true}));
                            } else {
                                let { suggestionData } = this.state.objMain?.state;
                                if(!suggestionData[fileKey]){
                                    suggestionData[fileKey] = {};
                                }
                                noDataState.counter_party_name.set(v=> ({...v, [fileKey]: false}));
                                suggestionData[fileKey]['counter_party_name'] = payload?.counter_party;
                            }
                            
                            invoiceNumberList.forEach(a=>{
                                const list = [];
                                let { formData, PDFFiles, filesSort } = this.state.objMain?.state;
                                let data = a;
                                if(!suggestionData[fileKey]){
                                  suggestionData[fileKey] = {}
                                }
                                suggestionData[fileKey] = {
                                  ...suggestionData[fileKey],
                                  invoice_number: {   
                                      exist: data.exist,
                                      name: data.name || "",
                                      registration_date: data.registration_date,
                                      address: data.address,
                                      registrated_number: data?.registrated_number  || data?.invoice_number
                                  }
                                }

                              //   PDFFile.formData.invoice_number = r?.payload[0]?.invoice_number;
                              //   console.log('PDFFiles',fileKey,Object.keys(PDFFiles)[0],fileKey === Object.keys(PDFFiles)[0])

                                if(fileKey === Object.keys(PDFFiles)[0]){
                                  formData.invoice_number = data?.registrated_number  || data?.invoice_number;
                                  PDFFiles[fileKey].invoice_number = data?.registrated_number  || data?.invoice_number;
                                      this.state.objMain.setState({ PDFFile, formData, });
                                }
                                isSuggestionLoading[fileKey] = false;
                                this.state.objMain.setState({
                                   suggestionData, 
                                   PDFFiles,
                                   isSuggestionLoading,
                                 });
                                isSuggestionLoading[fileKey] = false;
                                this.state.objMain.setState({isSuggestionLoading})
                                a.locations.forEach((b,i)=>{
                                    this.initOcrPreview(PDFFiles, fileKey, list, b, a.locations, i, 'invoice_number', a.invoice_number);
                                   
                                });
                            })
                            // console.log(previewScale);
                            if(r?.payload?.invoice_number_list?.length > 0 && findExist?.invoice_number) {
                                
                          
                            } else {
                                let { formData, PDFFiles, filesSort} = this.state.objMain?.state;
                                if(r?.payload?.length === 0){
                                    isSuggestionLoading[fileKey] = false;
                                    this.state.objMain.setState({isSuggestionLoading})
                                } else {
                                    let data = r?.payload?.invoice_number_list[0];
                                    if(!suggestionData[fileKey]){
                                        suggestionData[fileKey] = {}
                                    }
                                    suggestionData[fileKey] = {
                                        ...suggestionData[fileKey],
                                        invoice_number: {   
                                            exist: data?.exist,
                                            name: data?.name,
                                            registration_date: data?.registration_date,
                                            address: data?.address,
                                            registrated_number: data?.registrated_number || data?.invoice_number
                                        }
                                      }
                                    
                                    // if(PDFFiles[0]?.hash === fileKey || lengthFiles === 1){
                                        // if(!PDFFile.formData) PDFFile.formData = formData;
                                        // PDFFile.formData['invoice_number'] = data.invoice_number;
                                    
                                    
                                    // console.log('PDFFiles2',fileKey,Object.keys(PDFFiles)[0],fileKey === Object.keys(PDFFiles)[0])
                                    if(fileKey === Object.keys(PDFFiles)[0]){
                                        formData.invoice_number = data?.registrated_number  || data?.invoice_number;
                                        PDFFiles[fileKey].invoice_number = data?.registrated_number  || data?.invoice_number;
                                        this.state.objMain.setState({  formData, });
                                    }
                                    // console.log('PDFFiles2',PDFFiles[fileKey],formData)
                                    isSuggestionLoading[fileKey] = false;
                                    this.state.objMain.setState({  PDFFiles, suggestionData, isSuggestionLoading });
                                }
                            }
                        }).catch((e)=> {
                            console.log(e)
                            let { suggestionData } = this.state.objMain?.state;
                            suggestionData[fileKey] = {}
                            noDataState.counter_party_name.set(v=> ({...v, [fileKey]: true}));
                            noDataState.received_date.set(v=> ({...v, [fileKey]: true}));
                            noDataState.amount.set(v=> ({...v, [fileKey]: true}));
                            isSuggestionLoading[fileKey] = false;
                            this.state.objMain.setState({isSuggestionLoading, suggestionData})
                        });
                    }
                    // console.log(this.state.objMain.state.isFileLoading)
                    
                });
                // this.state.fileChangeCallback(PDFFile.hash);
                document.getElementById('pdf-upload').value = '';
                if(!this.state.objMain?.state?.isFileLoading) this.state?.fileChangeCallback(PDFFile.hash);
                
             });
            
            // URL.revokeObjectURL(objUrl);

            if (!multiple) {
                break;
            }
        }
        // this.state.fileChangeCallback(PDFFile.hash);
        document.getElementById('pdf-upload').value = '';
        
        // let { PDFFile, fileChangeCallback } = this.state;
       
    }

    attachmentExtensionAccept = () => {
		return [
			".txt",
			".doc",
			".docx",
			".ppt",
			".pptx",
			".xls",
			".xlsx",
			".gif",
			".jpg",
			".jpeg",
			".png",
			".mp4",
			".pdf",
			".psd",
			".ai",
			".zip",
			".7z",
			".gz",
			".lzh",
			".rar",
			".svg",
			".tiff",
            ".csv",
		];
	}

    displayModal = (title, body) => {
        const { modal } = this.state.objMain.state;
        modal.props = { show: true, centered: true };
        modal.title = title;
        modal.body = body;
        modal.close = true;
        this.state.objMain.setState({ modal });
    };

    genLoadingFile = async (file) => {
        const fileKey = await getFileHash(file);
        
        const PDFFile = {
            hash: fileKey,
            file: file,
            name: file.name,
            type: file.type,
            size: file.size,
            lastModified: file.lastModified,
            lastModifiedDate: file.lastModifiedDate,
        };
        this.setState(prevState => ({
            preShowFiles: {
                ...prevState.preShowFiles, 
                [fileKey]: PDFFile
            },
        }));

        return fileKey
    }

    loadProgessCb = (progressEvent, totalRequests) => {        
        let { progressContainer} = this.state;
        const { total, bytes } = progressEvent;

        const percentCompleted = Math.round((bytes * 100) / total) / totalRequests;
        const newProgressContainer = Math.min(progressContainer + percentCompleted, 90);
        
        this.setState({progressContainer: newProgressContainer});
    };

    // filter and convert file to pdf if needed
    preparePdfFiles = async (inputFiles) => {
        let { t } = this.props;
        try {
            this.setState({ isProcessingContainer: true, progressContainer: 10 });
            let files = [];
            const allowedImageTypes = ['.png', '.jpeg', '.gif', '.jpg'];
            let imageFiles = [];
            let filesToEmbed = [];
            
            for (let file of inputFiles) {
                const fileExtension = file.name.includes('.') ? '.' + file.name.split('.').pop().toLowerCase() : '';
    
                if (!this.attachmentExtensionAccept().includes(fileExtension)) {
                    this.displayModal(
                        t(`common:documents.verification.title.ivalid_file_extension`), 
                        t(`common:documents.verification.body.ivalid_file_extension`)
                    );
                    return [];
                }
    
                if (fileExtension === '.pdf') {
                    files.push(file);
                } else {
                    const fileKey = await this.genLoadingFile(file);
                    file.originalKey = fileKey;
                    if (allowedImageTypes.includes(fileExtension)) {
                        imageFiles.push(file);
                    } else {
                        filesToEmbed.push(file);
                    }
                }
                
            }
    
            const totalRequests = imageFiles.length * 2 + (filesToEmbed.length > 0 ? filesToEmbed.length + 1 : 0);
            console.log('totalRequests', totalRequests);
            
            for (let i = 0; i < imageFiles.length; i++) {
                const file = imageFiles[i];
                const formDataFile = new FormData();
                formDataFile.append('file', file, file.name);
    
                const response = await Service.convertFileToPdf(formDataFile, (progressEvent) =>
                    this.loadProgessCb(progressEvent, totalRequests)
                );
    
                const fileName = file.name.slice(0, file.name.lastIndexOf(".")) + ".pdf";
                const url = response?.payload?.url;
                const temp = response?.payload?.temp;
                const responseBlob = await Service.getDocumentBlob(url, (progressEvent) =>
                    this.loadProgessCb(progressEvent, totalRequests)
                );
                const pdfFile = new File([responseBlob], fileName, { type: 'application/pdf' });
                pdfFile.temp = temp;
    
                files.push(pdfFile);
            }
            
            if (filesToEmbed.length > 0) {
                const payload = filesToEmbed.map(file => ({ key: file.originalKey, name: file.name }));
                const responses = await Service.embedFileToPdf({files: payload}, (progressEvent) =>
                    this.loadProgessCb(progressEvent, totalRequests)
                );
                
                for (let i = 0; i < filesToEmbed.length; i++) {
                    const file = filesToEmbed[i];
                    const response = responses.payload.find(res => res.key === file.originalKey);

                    if (response) {
                        const fileName = file.name.slice(0, file.name.lastIndexOf(".")) + ".pdf";
                        const url = response.url;
                        const temp = response.temp;
                        const responseBlob = await Service.getDocumentBlob(url, (progressEvent) =>
                            this.loadProgessCb(progressEvent, totalRequests)
                        );
                        const pdfFile = new File([responseBlob], fileName, { type: 'application/pdf' });
                        pdfFile.temp = temp;
                        pdfFile.attachments = [file];
                        pdfFile.regenerate_cover = true;

                        files.push(pdfFile);
                    }
                }
            }
    
            this.setState({ progressContainer: 100 });
            await this.delay(1000);

            return files;
        } catch (error) {
            console.error("Prepare files error:", error);
            this.displayModal(
                t(`common:documents.verification.title.embed_file_error`),
                t(`common:documents.verification.body.embed_file_error`)
            );
    
            return [];
        } finally {
            this.setState({ isProcessingContainer: false, preShowFiles: {}});
        }
    }

    InputFileUpload = () => {
        let { multiple} = this.state;

        const extensionsAccept = [
            ...this.attachmentExtensionAccept(), 
            ...this.attachmentExtensionAccept().map(item => item.toUpperCase())
        ].join(',');

        return (
            <input type="file" name="pdf-upload" id="pdf-upload" accept={extensionsAccept}
                multiple={multiple}
                style={{ display: "none" }}
                onChange={async (ev) => {
                    if (ev.target.files?.length === 0) {
                        return
                    }
                    if (ev.target?.files?.length > 100) {
                        let { t } = this.props;
                        let { modal } = this.state.objMain.state;
                        modal.props = {
                          show: true,
                          dialogClassName: "loading-dialog",
                          centered: true,
                        };
                        modal.title = t(`common:documents.verification.title.excess_file_error`);
                        modal.body = t(`common:documents.verification.body.excess_file_error`);
                        modal.close = true;
              
                        this.state.objMain.setState({ modal });
                        return
                    }

                    this.state.objMain.setState({isFileLoading: true});
                    if (this.state?.objMain?.state?.activePage?.name === "VerificationUpload") {
                        this.setState({showLoadingPDFSelect: true});
                    }

                    if (ev.target.files?.length > 0) {
                        const files = await this.preparePdfFiles(ev.target.files);
                        await this.RegisterFiles(files);
                    }
                }}
            />
        );
    }

    FileUploadDropZone = (props) => {
        let { allowUpload } = this.state;
        let { InputFileUpload } = this;

        if (!allowUpload) {
            return null;
        }

        let className = `drop-zone-panel ${props.className || ""}`;

        return (
            <div className={className}>

                <label htmlFor="pdf-upload"
                    className="dropbox-area file-dropbox"
                    onDragOver={(ev) => {
                        ev.preventDefault();
                    }}
                    onDrop={async (ev) => {
                        ev.preventDefault();
                        let body = ev.target.closest(".pdf-fragment");
                        body.classList.remove("drag-over");
                        let dropPanel = body.querySelector(".file-upload-panel");
                        dropPanel.classList.remove("allow-drop");
                        const files = await this.preparePdfFiles(ev.dataTransfer.files);
                        await this.RegisterFiles(files);
                    }}
                    onDragLeave={(ev) => {
                        ev.preventDefault();
                        let body = ev.target.closest(".pdf-fragment");
                        body.classList.remove("drag-over");
                        let dropPanel = body.querySelector(".file-upload-panel");
                        dropPanel.classList.remove("allow-drop");
                    }}
                >
                    <div className="drop-area-inner">
                        <IconUploadFile />
                    </div>
                    <InputFileUpload />
                </label>
                <label className="info">ファイルをアップロード</label>

            </div>
        );
    }

    FileUploadPanel = () => {
        let { noFile, allowUpload } = this.state;
        let { FileUploadDropZone } = this;

        let panelClass = "";
        if (noFile && allowUpload) {
            panelClass = " allow-drop";
        } else if (noFile) {
            panelClass = " nofile";
        }

        if (!allowUpload) {
            return null;
        }

        return (
            <div className={`file-upload-panel${panelClass}`}>

                <FileUploadDropZone />

            </div>
        );
    }

    ActionFormSubmit = (ev) => {
        let { submitCB } = this.props;
        let { PDFFiles } = this.state;
        if (typeof submitCB === "function") {
            submitCB(ev, PDFFiles);
        }
    }

    ButtonFileUpload = () => {
        let { t } = this.props;
        let { allowUpload } = this.state;
        let { InputFileUpload } = this;

        if (!allowUpload) {
            return null;
        }

        return (
            <div className="button-file-upload">
                <label htmlFor="pdf-upload">
                    <span>{t("common:pdf-viewer.upload-new-file")}</span>
                    <InputFileUpload />
                </label>
            </div>
        );

    }

    ButtonPDFFileInfo = () => {
        let { multiple } = this.state;

        if (!multiple) {
            return false;
        }

        return (
            <div className="pdf-file-info">
                <div className="btn-info" onClick={(ev) => {
                    let { infoPanel } = this.state;
                    this.setState({ infoPanel: !infoPanel });
                }}>
                    <FontAwesomeIcon icon={faInfo} />
                </div>
            </div>
        );
    }

    ActionController = () => {
        let { formName, children } = this.props;
        let { controller, PDFFile } = this.state;
        let { ButtonFileUpload, ButtonPDFFileInfo } = this;

        if (!controller) {
            return null;
        }
        let PageInfo = 'Loading...'
        return (
            <div className="control-container">

                <ButtonPDFFileInfo />

                <div className="view-size-controller">
                    <div className="btn size-decrease" onClick={this.decreasePreviewSize}>
                        <FontAwesomeIcon icon={faMinus} />
                    </div>
                    <div className="btn size-increase" onClick={this.increasePreviewSize}>
                        <FontAwesomeIcon icon={faPlus} />
                    </div>
                </div>

                <ButtonFileUpload />

                <div className="page-index-info"> 
                    <StateFragment state={currentRangePage}>{s => {
                        // const state = s.get();
						// console.log(';diksjfolgsndối',s.get(), PDFFile?.numPages);
						return  <span className="info-label">{s.get().length > 0 && PDFFile?.numPages? <>{s.get()} / {PDFFile?.numPages}</> : 'Loading...'}</span>
					}}</StateFragment>
                
                </div>

                <div className="child-element">
                    {/* {ImprintStampController} */}
                    <Form id={formName} onSubmit={this.ActionFormSubmit}>
                        {children}
                    </Form>
                </div>

            </div>
        );
    }

    checkFileSize = (value) => {
        if (!value) {
            return value;
        }
        // console.log(value);
        let unit = "bytes";
        if (value > 1024) {
            value = value / 1024;
            unit = "KB";
        }
        if (value > 1024) {
            value = value / 1024;
            unit = "MB";
        }
        if (value > 1024) {
            value = value / 1024;
            unit = "GB";
        }
        if (value > 1024) {
            value = value / 1024;
            unit = "TB";
        }
        // console.log(value);

        value = parseFloat(value.toFixed(3));
        return `${value} ${unit}`;
    }

    OptionalPanel = () => {
        let { infoPanel, listPanel, noFile, PDFFile, docInfo, isGuest, isShowViewConfirm, isFileViewed, showInfoSectionMobile, isViewed, loadingViewDocument } = this.state;
        let {t} = this.props;
        let { PDFInfo, FileListSelector, FileUploadDropZone } = this;
        if (noFile || (!infoPanel && !listPanel)) {
            return false;
        }

        return (
            <div className={`pdf-info-section file-panel${listPanel ? " slide" : ""}`}>
                <FileUploadDropZone className="file-drop-zone" />
                <FileListSelector />
                <PDFInfo />
                {(isGuest || docInfo?.isGuestCollaboration) && isShowViewConfirm && !isFileViewed && !showInfoSectionMobile && this.state.width > 575
                    ? <div className="confirm-view-wrapper">
                            <h5 className='title-confirm-view'>{t("common:documents.general.viewing-confirm")}</h5>
                            <div className='body-confirm-view'>
                                <Checkbox
                                    style={{padding: 0}}
                                    color="primary"
                                    onChange={(ev) => {
                                        this.setState({ isViewed: ev.target.checked });
                                    }}
                                    sx={{
                                        color: blue[400],
                                        '&.Mui-checked': {
                                            color: blue[400],
                                        },
                                    }}
                                />
                                <span className="confirm-info-label">
                                    <span className="confirm-info-label-name">「{PDFFile?.name ?? ''}」</span>
                                    {t("common:documents.general.viewing-confirm-body")}
                                </span>
                            </div>

                        <Button className='btn-confirm-view' variant={isViewed ? "outline-primary" : "primary"} onClick={this.handleConfirmView} disabled={!isViewed}>
                            {loadingViewDocument ? (
                                    <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    />
                                ) : (
                                    <span>{t("common:documents.general.yes")}</span>
                                )}
                        </Button>
                    </div> 
                    : <></>}
            </div>
        );
    }

    FileListRemove = (props) => {
        // let { key } = props;
        let { allowUpload } = this.state;

        if (!allowUpload) {
            return null;
        }

        return (
            <button type="button" className="btn-icon file-remove" onClick={(ev) => {
                if(this?.state?.objMain?.state?.isFileLoading) return;
                let { PDFFiles, PDFFile, noFile, fileChangeCallback, fileRemoveCallback, multipleFilesQueue } = this.state;
                // let PDFFiles2= this.state.objMain.state.PDFFiles;
                let key = ev.target.closest(".file-item").dataset.file;
                let {originalFormData, formData, suggestionData, ocrFound} = this.state.objMain.state;
                if(PDFFiles[key]) delete PDFFiles[key];
                multipleFilesQueue = multipleFilesQueue.filter((item) => item !== key);
                // if(PDFFiles2[key]) delete PDFFiles2[key];ocrFound[PDFFile.hash]

                suggestionData[key] = {};
                ocrFound[key] = [];
                noDataState.counter_party_name.set(v=> {
                    if (v[key]) delete v[key];
                    return v
                });
                noDataState.received_date.set(v=> {
                    if (v[key]) delete v[key];
                    return v
                });
                noDataState.amount.set(v=> {
                    if (v[key]) delete v[key];
                    return v
                });
                if (key === PDFFile?.hash) {
                    PDFFile = {};
                    if (Object.keys(PDFFiles).length > 0) {
                        PDFFile = Object.values(PDFFiles)[0];
                        PDFFile.initialDone = false;
                    } else {
                        noFile = true;
                    }
		// console.log(PDFFile.formData || originalFormData)
		            formData = JSON.parse(JSON.stringify(PDFFile?.formData || originalFormData, replacerFunc()))
                    
                    this.setState({ PDFFiles, multipleFilesQueue }, () => {
                        
                        if (fileRemoveCallback && typeof fileRemoveCallback === "function") {
                            fileRemoveCallback(key);
                            this.setState({ PDFFile, noFile }, () => {
                                if (fileChangeCallback && typeof fileChangeCallback === "function") {
                                    fileChangeCallback(PDFFile.hash);
                                }
                            });
                        }
                        this.state.objMain.setState({formData, suggestionData, ocrFound});
                    });
                } else {
		            formData = JSON.parse(JSON.stringify(originalFormData, replacerFunc()))
                    this.setState({ PDFFiles, formData, multipleFilesQueue }, () => {
                        if (fileRemoveCallback && typeof fileRemoveCallback === "function") {
                            fileRemoveCallback(key);
                        }
                        this.state.objMain.setState({formData, suggestionData, ocrFound});
                    });
                }
                // this.state.objMain.setState({PDFFiles})
            }}>
                <CancelOutlinedIcon name="delete" />
            </button>
        );
    }

    FileListSelector = () => {
        let { PDFFiles, PDFFile, listPanel, updateInfo, filesSort,objMain, docInfo, isGuest, view_status, preShowFiles, isShowViewConfirm } = this.state;
        let { FileListRemove } = this;
        PDFFiles = {...PDFFiles, ...preShowFiles};
        let fileKeys = Object.keys(PDFFiles);
        if (!listPanel || !PDFFiles || fileKeys.length === 0) {
            return null;
        }

        if (filesSort?.length === 0 || !filesSort) {
            filesSort = Object.keys(PDFFiles);
        }

        return (
            <div className="file-upload-list">
                <FileDragDrop
                    fileOrder={filesSort}
                    isDraggable={updateInfo}
                    documentSection={"DocumentDetail"}
                    pdfFileObject={PDFFiles}
                    onChangeObject={(keyArray) => {
                        this.setState({
                            filesSort: keyArray,
                        }, () => {
                            if (this.handleChangeFileOrder) {
                                this.handleChangeFileOrder(keyArray);
                            }
                        })
                    }}
                    isShowViewStatus={!!docInfo?.document_view_required && !!(isGuest || docInfo?.isGuestCollaboration)}
                    viewStatus={view_status || {}}
                    selectedFile={PDFFile?.hash}
                    FileListRemove={FileListRemove}
                    isDisabled={objMain?.state?.isFileLoading}
                    onClickDocumentDetail={objMain?.state?.isFileLoading ? undefined:(ev, key) => {
                        console.log("==> onClickDocumentDetail");
                        
                        let {PDFFiles, PDFFile, fileChangeCallback} = this.state;
                        if (key !== PDFFile?.hash) {
                            PDFFile = PDFFiles[key];
                            PDFFile.initialDone = false;
                            // console.log(PDFFile)
                            if (PDFFile.url) {
                                PDFFile.file = PDFFile.url;
                            }
                            this.setState({PDFFile, showLoadingPDFLoad: true, isShowViewConfirm: false}, () => {
                                // console.log(fileChangeCallback)
                                if (fileChangeCallback && typeof fileChangeCallback === "function") {
                                    fileChangeCallback(PDFFile.hash);
                                }
                            });
                        }
                        // this.onChangeFile(ev, key);
                    }}
                />
            </div>
        );
    }

    PDFInfo = () => {
        let { t } = this.props;
        let { PDFFile, infoPanel, listPanel } = this.state;

        // if (!infoPanel || !PDFFile.initialDone) {
        if (!PDFFile?.initialDone) {
            return null;
        }

        // console.log(PDFFile);

        let paperW = PDFFile.pages[1].view.width / 72;
        let paperH = PDFFile.pages[1].view.height / 72;
        paperW = parseFloat(paperW.toFixed(2));
        paperH = parseFloat(paperH.toFixed(2));
        let layout = "portrait";
        if (paperW > paperH) {
            layout = "landscape";
        }

        let PaperSize = `${paperW} × ${paperH} in (${layout})`;
        let configDateFormat = {
            timeZone: "UTC",
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            hourCycle: "h23",
        }

        let formattedCreateDate = PDFFile.info.CreationDate ? formatDateIntlApi(new Date(PDFFile.info.CreateDate), configDateFormat): null;
        let formattedModifyDate = PDFFile.info.ModifyDate? formatDateIntlApi(new Date(PDFFile.info.ModifyDate), configDateFormat):null;
        let infoConfig = [
            {
                label: "file-name",
                value: PDFFile.name,
            },
            {
                label: "file-size",
                value: this.checkFileSize(PDFFile.size),
            },
            "separator",
            // {
            //     label: "title",
            //     value: PDFFile.info.Title,
            // },
            // {
            //     label: "author",
            //     value: PDFFile.info.Author,
            // },
            // {
            //     label: "subject",
            //     value: PDFFile.info.Subject,
            // },
            // {
            //     label: "keywords",
            //     value: "KEYWORD",
            // },
            // {
            //     label: "creation-date",
            //     value: formattedCreateDate ? `${formattedCreateDate.year}/${formattedCreateDate.month}/${formattedCreateDate.day} ${formattedCreateDate.hour}:${formattedCreateDate.minute}:${formattedCreateDate.second}`:'',
            // },
            // {
            //     label: "modification-date",
            //     value: formattedModifyDate ?`${formattedModifyDate.year}/${formattedModifyDate.month}/${formattedModifyDate.day} ${formattedModifyDate.hour}:${formattedModifyDate.minute}:${formattedModifyDate.second}`:'',
            // },
            // {
            //     label: "creator",
            //     value: PDFFile.info.Creator,
            // },
            // "separator",
            {
                label: "pdf-producer",
                value: PDFFile.info.Producer,
            },
            {
                label: "pdf-version",
                value: PDFFile.info.PDFFormatVersion,
            },
            {
                label: "page-count",
                value: PDFFile.numPages,
            },
            {
                label: "page-size",
                value: PaperSize,
            },
            "separator",
            {
                label: "fast-web-view",
                value: PDFFile.info.IsLinearized ? "Yes" : "No",
            },
            "separator",

        ];

        let ScanedInfo = t("common:general.not-available");
        if (PDFFile.pages[PDFFile.currentPage].scan) {
            let scanInfo = PDFFile.pages[PDFFile.currentPage].scan;
            ScanedInfo = `DPI: ${scanInfo.dpi} (${scanInfo.width}px x ${scanInfo.height}px)`;
        }

        infoConfig.push({
            label: "scaned-info",
            value: ScanedInfo,
        });

        // console.log(PDFFile.info);



        let InfoItems = [];
        for (let i in infoConfig) {
            let info = infoConfig[i];
            if (typeof info === "string") {
                InfoItems.push(<div key={i} className={info}></div>);
            } else {
                InfoItems.push(
                    <li key={i}>
                        <span className="label">{t(`common:pdf-viewer.pdf-info.${info.label}`)}</span>
                        <ul>
                            <li className="value" name="fileNameField">{info.value}</li>
                        </ul>
                    </li>
                );
            }
        }

        let { docInfo } = this.state;
        let CustomMetadata = PDFFile.info.Custom;

        if (docInfo) {
            CustomMetadata = {};
            if ([1, 2, 3, 4].includes(docInfo.function_type_id)) {
                CustomMetadata.Amount = docInfo.amount;
                CustomMetadata.Currency = docInfo.currency || "-";
                if (docInfo.function_type_id === 4) {
                    CustomMetadata.ReceivedDate = docInfo.received_date || "-";
                    CustomMetadata.CounterPartyName = docInfo.counter_party_name || "-";
                } else {
                    CustomMetadata.ContractDate = docInfo.contract_date || "-";
                    CustomMetadata.ExpiryDate = docInfo.expiry_date || "-";
                }
                CustomMetadata.ProductName = docInfo.product_name || "-";
                CustomMetadata.RefDocument = docInfo.reference || "-";
            }

            for (let cusKey in docInfo.attributes) {
                CustomMetadata[cusKey] = docInfo.attributes[cusKey] || "-";
            }
        }

        // if (CustomMetadata) {
        //     InfoItems.push(<div key="custom-separator" className="separator"></div>);
        //     for (let i in CustomMetadata) {
        //         let customValue = CustomMetadata[i];
        //         if (typeof customValue !== "string") {
        //             if (customValue.hasOwnProperty("name")) {
        //                 customValue = customValue.name;
        //             } else {
        //                 customValue = null;
        //             }
        //         }
        //         if (customValue) {
        //             InfoItems.push(
        //                 <li key={`custom-${i}`}>
        //                     <span className="label">{i}</span>
        //                     <ul>
        //                         <li className="value" name="fileNameField">{customValue}</li>
        //                     </ul>
        //                 </li>
        //             );
        //         }
        //     }
        // }
        // console.log(InfoItems);

        let InfoHeaderPanel = (ev) => {
            if (!listPanel) {
                return null;
            }

            return (
                <div className="pdf-info-header separator">
                    <span>{t("common:pdf-viewer.pdf-info-header")}</span>
                    <button className="btn-icon pdf-info-close" onClick={(ev) => {
                        this.setState({ infoPanel: false });
                    }}>
                        <CloseOutlinedIcon />
                    </button>
                </div>
            );
        }

        return (
            <div className={`pdf-properties${infoPanel ? " active" : ""}`}>
                <InfoHeaderPanel />
                {InfoItems}
            </div >
        );
    }

    allowDropHandler = (ev) => {
        let { allowUpload } = this.state;

        if (allowUpload) {
            let body = ev.target.closest(".pdf-fragment");
            body.classList.add("drag-over");
            let dropPanel = body.querySelector(".file-upload-panel");
            dropPanel.classList.add("allow-drop");
        }
    };

    showViewConfirm = () => {
        let { docInfo, PDFFile, view_status, isGuest, fullPreview } = this.state;

        const document_view_required = !!docInfo?.document_view_required

        const isFileViewed = view_status?.[PDFFile?.hash]?.status == 1;

        if (!(isGuest || docInfo?.isGuestCollaboration) || !document_view_required || isFileViewed || !fullPreview) {
            return
        }

        this.setState({ isShowViewConfirm: true });
    }

    handleConfirmView = () => {
        let { docInfo, PDFFile, PDFFiles, token, isViewed, view_status } = this.state;
        this.setState({loadingViewDocument: true});
        if (isViewed && token) {
            const files = PDFFiles ?? {};

            let file_viewed = {}
            
            Object.entries(files).forEach(([fileKey, info]) => {
                const file = view_status[fileKey];
                file_viewed[fileKey] = {
                    ...(file ?? {}),
                    file_name: info.name,
                };

                if (!file) {
                    file_viewed[fileKey].status = 0;
                }
                
                
                if (fileKey === PDFFile?.hash) {
                    file_viewed[fileKey].status = 1;
                    file_viewed[fileKey].is_new = true;
                } else {
                    delete file_viewed[fileKey].is_new;
                }
            });

            console.log("==> file_viewed", {file_viewed});

            Service.ViewDocument(token, {
                function_type: docInfo?.function_type_id,
                file_viewed: file_viewed
            }).then((res) => {
                console.log("==> res", {res});
                this.onConfirmView(res.result)
                this.setState({ view_status: res.result, isShowViewConfirm: false, isViewed: false, loadingViewDocument: false });
            }).catch((err) => {
                console.log(err.response);
                this.setState({ loadingViewDocument: false });
            });
        }
        if(isViewed && docInfo?.isGuestCollaboration){
            const files = PDFFiles ?? {};

            let file_viewed = {}
            
            Object.entries(files).forEach(([fileKey, info]) => {
                const file = view_status[fileKey];
                file_viewed[fileKey] = {
                    ...(file ?? {}),
                    file_name: info.name,
                };

                if (!file) {
                    file_viewed[fileKey].status = 0;
                }
                
                
                if (fileKey === PDFFile?.hash) {
                    file_viewed[fileKey].status = 1;
                    file_viewed[fileKey].is_new = true;
                } else {
                    delete file_viewed[fileKey].is_new;
                }
            });

            console.log("==> file_viewed", {file_viewed});

            Service.ViewDocumentGuestCollab({
                function_type: docInfo?.function_type_id,
                file_viewed: file_viewed,
                signer_id: docInfo?.signer_info.user_id,
                document_id: docInfo?.document_id
            }).then((res) => {
                console.log("==> res", {res});
                this.onConfirmView(res.result)
                this.setState({ view_status: res.result, isShowViewConfirm: false, isViewed: false, loadingViewDocument: false });
            }).catch((err) => {
                console.log(err.response);
                this.setState({ loadingViewDocument: false });
            });
        }
    }

    ViewNote = () => {
        let { t } = this.props;
        let { fullPreview, isShowViewConfirm, docInfo, isGuest, view_status, PDFFile } = this.state;
        const document_view_required = !!docInfo?.document_view_required

        const isFileViewed = view_status?.[PDFFile?.hash]?.status == 1;

        if (!fullPreview || isShowViewConfirm || !document_view_required || !isGuest || isFileViewed) {
            return null
        }

        return (
            <div className='view-note-container'>
                <div className="view-note">
                    <span className="info-label">
                        {t("common:documents.general.scroll-note")}
                    </span>
                </div>
            </div>
        );
    }

    modalConfirmDelete = (confirm) => {
        if(confirm){
            let {
                PDFFile,
                // tempPassword,
                modalPassword,
                incorrectPassword,
                noFile,
                PDFFiles
              } = this.state;
              // tempPassword = "";
              modalPassword = false;
              incorrectPassword = "";
              if (!PDFFile.password) {
                delete PDFFiles[PDFFile.hash];
                PDFFile = { initialDone: false };
                if (Object.keys(PDFFiles).length > 0) {
                  PDFFile = Object.values(PDFFiles)[0];
                  noFile = false;
                } else {
                  noFile = true;
                }
                console.log(PDFFile, noFile);
              }
              this.setState({
                PDFFiles,
                PDFFile,
                modalPassword,
                incorrectPassword,
                noFile,
                modalConfirmDeleteShow: false
              });
        }else{
            this.setState({modalPassword: true, modalConfirmDeleteShow: false})
        }
        
        
    }

    updateShowFullPreview = (value) => {   
        this.setState({ showFullPreview: value });
    };

    render() {
        let { t, defaultScale } = this.props;
        let {
            PDFFiles,
            PDFFile,
            showAll,
            noFile,
            showFullPreview,
            fullPreview,
            modalPassword,
            multipleFilesQueue,
            docInfo,
            isGuest,
            isShowViewConfirm,
            isViewed,
            token,
            view_status,
            showLoadingPDFSelect,
            isLastPageRendered,
            modalConfirmDeleteShow,
            loadingViewDocument,
            progressContainer,
            isProcessingContainer,
            showInfoSectionMobile,
            width
        } = this.state;
        let {
            PDFPages,
            PDFPageNoData,
            MiniViewAction,
            PageNavigate,
            FileUploadPanel,
            ActionController,
            OptionalPanel,
            ViewNote,
        } = this;
        // console.log(PDFFiles);
        // console.log("==> PDFFile", PDFFile);
        // console.log(noFile);
        let pdfFullPreview = "";
        if (showAll) {
            pdfFullPreview = " pdf-full-preview";
        }

        let noFileClass = "";
        if (noFile) {
            noFileClass = " no-file";
        }

        let pdfMainProps = {};
        if (!fullPreview) {
            pdfMainProps.id = "pdf_main_preview";
        }
        // console.log('this.state.filesSort', this.state.filesSort)
        // console.log('PDFがありません。', PDFFile, multipleFilesQueue)
        const isFileViewed = view_status?.[PDFFile?.hash]?.status == 1;
// console.log('this.state.showLoadingPDFLoad', this.state.showLoadingPDFLoad);
        return [
            <div key="main-preview" {...pdfMainProps} className={`pdf-fragment${pdfFullPreview}`}
                onDragEnter={this.allowDropHandler}
            >
                <div className={`pdf-preview-section${noFileClass}`}>
                    {/* {this.state.showLoadingPDFSelect  ? 
                        <div className='showLoading'>
                            <Loading/>
                        </div>
                    :null} */}

                    {this.state.showLoadingPDFSelect ? (
                        <div className='showLoading'>
                            <LoadingProgress progress={progressContainer} showProgress={isProcessingContainer}/>
                        </div>
                    ) : null}

                    {/* <div className='showLoading'>
                        <LoadingProgress progress={60} showProgress={true}/>
                    </div> */}

                    {!this.state.showFullPreview ? <><div className="pdf-preview-panel" ref={this.containerRef}
                        onMouseUp={this.mouseUpHandle.bind(this)}
                        onMouseMove={this.mouseMoveHandle.bind(this)} 
                        onMouseDown={this.mouseDownHandle.bind(this)} >
                        <DocumentWithObserver file={PDFFile?.file}
							observerRef={this.containerRef}
							currentRangePage={currentRangePage}
                            showLoading={this.state.showLoadingPDFLoad && this.state?.objMain?.state?.activePage?.name !== "VerificationUpload"}
							setCurrentPage={(page)=> currentRangePage.set(`${page}`)}
                            onLoadSuccess={this.onDocumentLoadSuccess}
                            onLoadError={this.onDocumentLoadError}
                            onLoadProgress={()=>{
                                if(window.location.hash === 'VerificationUpload') {
                                    this.state.objMain.setState({isFileLoading: true});
                                }
                            }}
                            // onSourceSuccess={this.onDocumentRenderSuccess}
                            onPassword={this.onPasswordHandler}
                            options={this.pdfDocOptions}
                            loading={Loading}
                            noData={PDFPageNoData}
                            isNeedViewConfirm={!!docInfo?.document_view_required}
                            onShowViewConfirm={this.showViewConfirm}
                            fileHash={PDFFile?.hash}
                            fullPreview={fullPreview}
                            isLastPageRendered={isLastPageRendered}
                        >
                            <PDFPages />
                        </DocumentWithObserver>
                    </div>

                    <div className="pdf-controller-panel">
                        <ActionController />
                        <MiniViewAction />
                        <PageNavigate />
                        <FileUploadPanel />
                        <ViewNote />
                    </div></>:<>
                    <div className="pdf-preview-panel"  >
                        
                    </div>

                    <div className="pdf-controller-panel">
                    </div>
                    </>}
                    

                </div>

                <OptionalPanel />

            </div>,
            <FullPreview 
                key="full-preview" 
                scale={defaultScale} 
                file={PDFFile} 
                files={PDFFiles} 
                filesSort={this.state.filesSort} 
                active={showFullPreview} 
                docInfo={docInfo} 
                onConfirmView={this.handleView} 
                view_status={view_status} 
                token={token} 
                isGuest={isGuest} 
                onClose={(ev) => {
                    this.setState({ showFullPreview: false });
                }}
                onClickMenuMobile={() => {
                    this.setState({ showInfoSectionMobile: !showInfoSectionMobile})
                }}
                showInfoSectionMobile={showInfoSectionMobile}
                showConfirmView={(isGuest || docInfo?.isGuestCollaboration) && isShowViewConfirm && !isFileViewed && !showInfoSectionMobile}
            />,
            <>
                {(isGuest || docInfo?.isGuestCollaboration) && isShowViewConfirm && !isFileViewed && !showInfoSectionMobile && this.state.width <= 575
                    ? <div className="confirm-view-wrapper">
                            <h5 className='title-confirm-view'>{t("common:documents.general.viewing-confirm")}</h5>
                            <div className='body-confirm-view'>
                                <Checkbox
                                    style={{padding: 0}}
                                    color="primary"
                                    onChange={(ev) => {
                                        this.setState({ isViewed: ev.target.checked });
                                    }}
                                    sx={{
                                        color: blue[400],
                                        '&.Mui-checked': {
                                            color: blue[400],
                                        },
                                    }}
                                />
                                <span className="confirm-info-label">
                                    <span className="confirm-info-label-name">「{PDFFile?.name ?? ''}」</span>
                                    {t("common:documents.general.viewing-confirm-body")}
                                </span>
                            </div>

                        <Button className='btn-confirm-view' variant={isViewed ? "outline-primary" : "primary"} onClick={this.handleConfirmView} disabled={!isViewed}>
                            {loadingViewDocument ? (
                                    <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    />
                                ) : (
                                    <span>{t("common:documents.general.yes")}</span>
                                )}
                        </Button>
                    </div> 
                    : <></>}
            </>,
            <Modal key="modal-password" show={modalPassword} backdrop="static" animation={false} onHide={this.state.modalPasswordClose} >
                <Form name="pdf-password-form">
                    <Modal.Header closeButton={false}>
                        <Modal.Title>{t("common:documents.general.password-required")}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Form.Row>
                            <Form.Group as={Col} controlId="password" className={this.state.incorrectPassword}>
                                <Form.Label style={{whiteSpace: 'pre-line'}}>{t('common:documents.general.pdf-password', {file_name: PDFFile.name})}</Form.Label>
                                <Form.Control
                                    name='pdf-password'
                                    type='password'
                                    placeholder={t('common:documents.general.pdf-password', {file_name: PDFFile.name})}
                                    onChange={(ev) => {
                                        this.setState({ tempPassword: ev.target.value });
                                    }}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    {t('common:message.input.incorrect-password')}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={(ev) => {this.setState({modalConfirmDeleteShow: true, modalPassword: false})}}>
                            <span>{t("common:general.close")}</span>
                        </Button>

                        <Button key="ok" variant="primary" onClick={(ev) => {
                            // this.state.incorrectPassword = "was-validated";
                            // this.state.modalPassword = false;
                            let { PDFFile, tempPassword, modalPassword, incorrectPassword } = this.state;
                            PDFFile.tryPassword = tempPassword;
                            tempPassword = "";
                            modalPassword = false;
                            incorrectPassword = "was-validated";
                            this.setState({ PDFFile, tempPassword, modalPassword, incorrectPassword }, this.state.modalPasswordClose);
                            // fnCallback(tempPDFPassword);
                            // this.objMain.modalToggle(false);
                        }}>
                            <span>{t("common:general.ok")}</span>
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>,
            <Modal show={modalConfirmDeleteShow} animation={false} onHide={(ev) => { this.modalConfirmDelete(false) }} centered={true} >
                <Modal.Header closeButton>
                <Modal.Title>{t('common:document.title.pdf-password-confirm-delete')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{t('common:document.message.pdf-password-confirm-delete')}</Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={(ev) => { this.modalConfirmDelete(false) }}>{t('common:auth.general.cancel')}</Button>
                <Button variant="primary" onClick={(ev) => { this.modalConfirmDelete(true) }}>{t('common:auth.general.ok')}</Button>
                </Modal.Footer>
                
            </Modal>

        ];

    }

}

export default withTranslation(undefined, {withRef: true})(Main);
